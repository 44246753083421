import { Component, EventEmitter, Output , Inject, Input  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export interface DialogData {
  title: string;
  primaryOptions: string[];
  secondaryOptions?: string[];
  enableTextArea?: boolean;
  textAreaPlaceholder?: string;
}

@Component({
  selector: 'app-dialog-out-of-scope',
  templateUrl: './dialog-out-of-scope.component.html',
  styleUrls: ['./dialog-out-of-scope.component.scss']
})


export class DialogOutOfScopeComponent {

  selectedValue: string | null = null;
  selectedSecondaryValue: string | null = null;
  showSecondaryOptions = false;
  showTextArea = false;
  otherText = '';
 
  @Output() dataSelected = new EventEmitter<string>();
  
  constructor(
    public dialogRef: MatDialogRef<DialogOutOfScopeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
   ){}


  onSelectionChange(selectedValue: string) {
    this.selectedValue = selectedValue;
    this.showTextArea = selectedValue === 'Other' && this.data.enableTextArea;
    this.showSecondaryOptions = this.data.secondaryOptions && selectedValue === 'Called Not Answered';
    this.selectedSecondaryValue = null;
  }

  onSecondaryOptionChange(selectedSecondaryValue: string) {
    this.selectedSecondaryValue = selectedSecondaryValue;
  }

  closeDialog() {
    let returnValue: string;
    if (this.showTextArea) {
      returnValue = this.otherText;
    } else if (this.showSecondaryOptions) {
      returnValue = `${this.selectedValue} - ${this.selectedSecondaryValue}`;
    } else {
      returnValue = this.selectedValue!;
    }
    this.dataSelected.emit(returnValue);
    this.dialogRef.close(returnValue);
  }

  canSubmit(): boolean {
    if (this.showTextArea) {
      return this.otherText.trim() !== '';
    }
    if (this.showSecondaryOptions) {
      return !!this.selectedSecondaryValue;
    }
    return !!this.selectedValue;
  }
  

}
