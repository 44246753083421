<h2 mat-dialog-title>{{data?.title}}</h2>
<div mat-dialog-content>
    <mat-form-field class="full-width" appearance="outline">
        <mat-select [placeholder]="data?.textAreaPlaceholder" (selectionChange)="onSelectionChange($event.value)">
            <mat-option *ngFor="let option of data?.primaryOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="showSecondaryOptions" class="full-width" appearance="outline">
        <mat-select placeholder="Choose a sub-option" (selectionChange)="onSecondaryOptionChange($event.value)">
            <mat-option *ngFor="let option of data?.secondaryOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="showTextArea" class="full-width" appearance="outline">
        <textarea matInput placeholder="Please specify" [(ngModel)]="otherText"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button class="mm-bg-pink mm-text-white" (click)="dialogRef.close()">Cancel</button>
    <button mat-flat-button class="mm-bg-primary mm-text-white" [disabled]=" !canSubmit()"
        (click)="closeDialog()">Submit</button>
</div>